








































































































































import { Component, Vue } from "vue-property-decorator";
import cardBox from "@/components/card-box/index.vue";
import pagination from "@/components/pagination/index.vue";
import { PageNum } from "@/tool/interface-index";
import {
  deleteExamProject,
  getExamProjectList,
  getExamProjectSchoolList,
  copyExamProject,
} from "@/api/examProject";
import { getExamTimeList } from "@/api/examTime";
import { ExamProject, ExamProjectSchool } from "@/classes/exam-project";
import { ExamTime } from "@/tool/interface-index";
import * as ResApi from "@/api/examFileResource";
import * as ExamPrjApi from "@/api/examProject";
import * as _ from "lodash";
import { UserModule } from "@/store/modules/user";

interface ExamProjectExt extends ExamProject {
  loadingScore: boolean;
  loadingScoreIndex: number;
  loadingScoreCount: number;
}

@Component({
  name: "ExamProjectListPage",
  components: {
    cardBox,
    pagination,
  },
})
export default class extends Vue {
  private pageNum: PageNum = {
    totalPage: 0,
    curPage: 1,
    pageSize: 10,
  };

  private query: {
    keyWords: string;
    examSchoolId: string;
    examProjectId: string;
    examStartTimeWithinDays: number;
  } = {
    examSchoolId: "",
    keyWords: "",
    examProjectId: "",
    examStartTimeWithinDays: 0, //360,
  };

  private queryExamProjectDataClick() {
    this.pageNum.curPage = 1;
    this.getExamProjectData();
  }

  private tableData: Array<ExamProjectExt> = [];

  private get disabledOperation(): boolean {
    console.log(`roles:${UserModule.roles}`);
    if (this.$route.meta.roles) {
      let roled: boolean = false;
      for (let i = 0; i < this.$route.meta.roles.length; i++) {
        roled =
          roled ||
          UserModule.roles.some((item) => item == this.$route.meta.roles[i]);
        if (roled) {
          break;
        }
      }
      if (!roled) {
        return true;
      }
      console.log(`meta.roles:${this.$route.meta.roles},roled:${roled}`);

      if (
        UserModule.roles.some(
          (item) => item == "admin" || item == "formalExamAdmin"
        )
      ) {
        return false;
      }

      /*
      return (
        UserModule.roles.findIndex((role: string) => {
          return role.toLowerCase() == "teacher";
        }) >= 0
      );
      */
    }
    return true;
  }

  private loading: boolean = false;
  private async getExamProjectData() {
    try {
      this.loading = true;
      const res = await getExamProjectList({
        examType: "formalExamMode",
        pageSize: this.pageNum.pageSize,
        curPage: this.pageNum.curPage,
        //
        keyWords: this.query.keyWords,
        examSchoolId: this.query.examSchoolId,
        examProjectId: this.query.examProjectId,
        examStartTimeWithinDays: this.query.examStartTimeWithinDays,
      });
      this.tableData = res.data.items.map((item: any) => {
        return _.merge(item, {
          loadingScore: false,
          loadingScoreIndex: 0,
          loadingScoreCount: 0,
        });
      });
      this.pageNum.totalPage = res.data.totalPage;
    } finally {
      this.loading = false;
    }
  }
  private deleteData(examProjectId: string) {
    this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(() => {
        deleteExamProject(examProjectId).then((res: any) => {
          if (res.code == 0) {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getExamProjectData();
          }
        });
      })
      .catch(() => {});
  }

  private setExam(examProjectId: string, type: string) {
    this.$router.push({
      path:
        type == "edit"
          ? "editExamProject"
          : type == "add"
          ? "newExamProject"
          : "viewExamProject",
      query: {
        examProjectId: examProjectId,
        type: type,
      },
    });
  }

  private loadingScore: boolean = false;
  async scoreExamTime(params: {
    examProjectId: string;
    examSchoolId: string;
    examTimeId: string;
    scoreExamAnswerPackages: boolean;
    scoreLocalExamAnswerPackageFiles: boolean;
    recipients: string;
  }) {
    try {
      this.loadingScore = true;
      //
      await ExamPrjApi.postScoreExamProject(params, true);
    } finally {
      this.loadingScore = false;
    } //try 0
  }

  handleExportExamProjectFileClick(project: ExamProject) {
    try {
      const baseUrl: string = process.env.VUE_APP_BASE_API || "";
      ResApi.download(
        `${baseUrl}${
          baseUrl.endsWith("/") ? "" : "/"
        }v1/download/examProjectFiles?examProjectId=${project.examProjectId}`,
        {},
        `${project.examProjectName}.xkwz`
      );
    } catch (error) {
      //
    }
  }

  private async downloadExamStudentReport(
    fileName: string,
    examProjectId: string,
    examSchoolId: string,
    examTimeId: string
  ) {
    //const baseUrl = process.env.VUE_APP_BASE_API;
    //const examStudentsReportShortFileUrl = `/api/v1/examComment/statistics/download/examStudentsReport?examProjectId=${row.examProjectId}&examSchoolId=${row.examSchoolId}&examTimeId=${row.examTimeId}&fileName=${fileName}`;
    const examStudentsReportShortFileUrl = `/api/v1/examComment/statistics/download/examStudentsReport?examProjectId=${examProjectId}&examSchoolId=${examSchoolId}&examTimeId=${examTimeId}&fileName=${fileName}`;

    await ResApi.download(`${examStudentsReportShortFileUrl}`, {}, fileName);

    /*
              //const baseUrl = process.env.VUE_APP_BASE_API;
        //const examStudentsReportShortFileUrl = `/api/v1/examComment/statistics/download/examStudentsReport?examProjectId=${row.examProjectId}&examSchoolId=${row.examSchoolId}&examTimeId=${row.examTimeId}&fileName=${fileName}`;
        const examStudentsReportShortFileUrl = `/api/v1/examComment/statistics/download/examStudentsReport?examProjectId=${examProjectId}&examSchoolId=${examSchoolId}&examTimeId=${examTimeId}&fileName=${fileName}`;

        await ResApi.download(
          `${examStudentsReportShortFileUrl}`,
          {},
          fileName
        );
      */
  }

  private loadingDownloadSumaryExamStatisticsReport: boolean = false;
  private async doDownloadSumaryExamStatisticsReportClick(
    project: ExamProject
  ) {
    if (this.loadingDownloadSumaryExamStatisticsReport) {
      this.$message({
        message: "仍有下载任务进行中，请稍候再试。",
        type: "info",
        duration: 5 * 1000, // 100000
        offset: 60,
      });
      return;
    }
    try {
      this.loadingDownloadExamStatisticsReport = true;
      //
      const examProjectId = project.examProjectId;
      const examSchoolId = "";
      const examTimeId = "";

      const fileName = `${project.examProjectName}_考生成绩明细[(不含缺考)].xlsx`;
      this.$message({
        message: `开始下载: ${fileName} ...`,
        type: "success",
        duration: 5 * 1000, // 100000
        offset: 60,
      });
      await this.downloadExamStudentReport(
        fileName,
        examProjectId,
        examSchoolId,
        examTimeId
      );
    } finally {
      this.loadingDownloadExamStatisticsReport = false;
    }
  }

  private loadingDownloadExamStatisticsReport: boolean = false;
  private async doDownloadExamStatisticsReportClick(project: ExamProject) {
    if (this.loadingDownloadExamStatisticsReport) {
      this.$message({
        message: "仍有下载任务进行中，请稍候再试。",
        type: "info",
        duration: 5 * 1000, // 100000
        offset: 60,
      });
      return;
    }
    try {
      this.loadingDownloadExamStatisticsReport = true;
      const examProjectId = project.examProjectId;

      const { data } = await getExamProjectSchoolList({
        examProjectId: project.examProjectId,
        curPage: 1,
        pageSize: 9999,
      });

      for (let i = 0; i < data.items.length; i++) {
        const examSchoolId = data.items[i].examSchoolId || "";
        const examTimeId = "";
        const fileName = `${data.items[i].examProjectName}_${data.items[i].examSchoolName}_考生成绩明细[(不含缺考)].xlsx`;
        this.$message({
          message: `开始下载(${i + 1}/${data.items.length}):${fileName} ...`,
          type: "success",
          duration: 5 * 1000, // 100000
          offset: 60,
        });

        await this.downloadExamStudentReport(
          fileName,
          examProjectId,
          examSchoolId,
          examTimeId
        );
      }
    } catch (error) {
      //
    } finally {
      this.loadingDownloadExamStatisticsReport = false;
    }
  }

  private handleMoreOptCommand(command: string, project: ExamProject) {
    switch (command.toLowerCase()) {
      case "CopyExamProject".toLowerCase(): {
        this.doCopyExamProject(project);
        break;
      }
      case "ExportExamProjectFile".toLowerCase(): {
        this.handleExportExamProjectFileClick(project);
        break;
      }
      case "DownloadExamStatisticsReport".toLowerCase(): {
        //导出学校成绩表
        this.doDownloadExamStatisticsReportClick(project);
        break;
      }
      case "DownloadSumaryExamStatisticsReport".toLowerCase(): {
        //导出项目成绩表
        this.doDownloadSumaryExamStatisticsReportClick(project);
        break;
      }
    }
  }

  private async doCopyExamProject(project: ExamProject) {
    const { value: newExamProjectName } = await this.$prompt(
      "请输入新项目名",
      "复制考务",
      {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }
    );
    await copyExamProject(project.examProjectId, {
      newExamProjectName,
      copyExamPapers: true,
      copyExamStudents: true,
    });
    this.$message({
      type: "success",
      message: "复制成功!",
    });
    this.getExamProjectData();
  }

  async doReScoringExamProjectClick(project: ExamProject, tag: string) {
    if (_.get(project, "loadingScore", false)) {
      this.$message({
        message: "仍有测评任务处理中，请稍候再试。",
        type: "info",
        duration: 5 * 1000, // 100000
        offset: 60,
      });
      return;
    }
    await this.$confirm(`测评：${project.examProjectName}, 是否继续?`, "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    });
    try {
      _.set(project, "loadingScore", true);
      //2021/09/08测评不需要邮件与发邮件功能分离
      //await this.promptRecipients();
      //
      switch (tag) {
        case "examAnswerPackageFiles": {
          //评答案包文件(测评异常考生)
          try {
            this.$message({
              message: `开始测评异常考生数据包 ...`,
              type: "success",
              duration: 5 * 1000, // 100000
              offset: 60,
            });
            _.set(project, "loadingScoreIndex", 0);
            _.set(project, "loadingScoreCount", 1);
            await this.scoreExamTime({
              examProjectId: project.examProjectId,
              examSchoolId: "",
              examTimeId: "",
              scoreExamAnswerPackages: false,
              scoreLocalExamAnswerPackageFiles: true,
              recipients: "" /*this.recipients*/,
            });
            _.set(project, "loadingScoreIndex", 1);
          } catch (error) {}
          break;
        }
        default: {
          //评答案包记录
          const { data } = await getExamTimeList({
            examProjectId: project.examProjectId,
            curPage: 1,
            pageSize: 99999,
          });
          _.set(project, "loadingScoreIndex", 0);
          _.set(project, "loadingScoreCount", data.items.length);
          let tasks: any[] = [];
          for (let i = 0; i < data.items.length; i++) {
            const examTime = data.items[i];
            tasks.push(
              this.scoreExamTime({
                examProjectId: examTime.examProjectId,
                examSchoolId: examTime.examSchoolId,
                examTimeId: examTime.examTimeId,
                scoreExamAnswerPackages: true,
                scoreLocalExamAnswerPackageFiles: false,
                recipients: "" /*this.recipients*/,
              })
            );

            if (i % 10 == 0 || i == data.items.length - 1) {
              try {
                this.$message({
                  message: `开始测评(${i + 1}/${data.items.length}):${
                    examTime.examTimeName
                  } ...`,
                  type: "success",
                  duration: 5 * 1000, // 100000
                  offset: 60,
                });
                await Promise.all(tasks);
                _.set(project, "loadingScoreIndex", i + 1);
              } catch (error) {
                //
              } finally {
                tasks.splice(0, tasks.length);
              }
            }
            //
            /*
            //每次一个太慢了
            try {
              this.$message({
                message: `开始测评(${i + 1}/${data.items.length}):${
                  examTime.examTimeName
                } ...`,
                type: "success",
                duration: 5 * 1000, // 100000
                offset: 60,
              });
              await this.scoreExamTime({
                examProjectId: examTime.examProjectId,
                examSchoolId: examTime.examSchoolId,
                examTimeId: examTime.examTimeId,
                scoreExamAnswerPackages: true,
                scoreLocalExamAnswerPackageFiles: false,
                recipients: "" ,
              });
              _.set(project, "loadingScoreIndex", i + 1);
            } catch (error) {}
            */
          } //for
        }
      } //switch
      //
      this.$message({
        message: "测评任务已全部提交，请去查看测试结果。",
        type: "info",
        duration: 5 * 1000, // 100000
        offset: 60,
      });
    } finally {
      _.set(project, "loadingScore", false);
    }
  }

  private upDataPage() {
    this.getExamProjectData();
  }

  mounted() {
    //this.getExamProjectData();
  }
  activated() {
    this.getExamProjectData();
  }
}
